import VisitorPerDay from "./components/widgets/VisitorPerDay";
import KeyShopNumber from "./components/widgets/KeyShopNumber";
import {defaultNavigationConfig} from "./omnia/components/layouts/dashboard/config";

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',
    domain: "simply-warm.groon.io",
    protocol: "https",
    socket_protocol: 'wss',

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: [
        defaultNavigationConfig.home,
        defaultNavigationConfig.creativeStudio,
        {
            title: 'layout.company',
            icon: 'Building01',
            path: null,
            isModules: true,
            items: [
                defaultNavigationConfig.analytics,
                defaultNavigationConfig.resources,
                defaultNavigationConfig.connections,
                defaultNavigationConfig.projects,
                defaultNavigationConfig.marketing,
                {
                    title: 'Simply Warm',
                    permissions: [],
                    icon: 'Award01',
                    items: [
                        {
                            title: 'Settings',
                            path: '/shop/settings',
                            icon: 'Settings02',
                        },
                        {
                            title: 'Orders',
                            path: '/shop/orders',
                            icon: 'ReceiptCheck',
                        },
                        {
                            title: 'Products',
                            path: '/shop/products/overview',
                            icon: 'ShoppingCart01',
                        },
                        {
                            title: 'Customers',
                            path: '/shop/visitors',
                            icon: 'Users01',
                        },
                        {
                            title: 'Website',
                            path: '/shop/website',
                            icon: 'LayoutAlt02',
                        }
                    ]
                }
            ]
        },
    ],

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [
        {
            identifier: 'VISITORS_PER_DAY',
            module: "Shop",
            name: 'Besucher pro Tag',
            component: VisitorPerDay,
        },
        {
            identifier: 'KEY_SHOP_NUMBER',
            module: "Shop",
            name: 'Kennzahlen Shop',
            component: KeyShopNumber,
        }
    ],

}
